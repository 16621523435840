import { useEffect, useState } from "react";
import {
  CartMessage,
  PartialProduct,
  AddonOption,
  IDictionary,
  ProductAddon,
  AddonFieldType,
} from "@tides/base-library/dist/types";
import {
  BaseAddonResult,
  IAddonResult,
  SliderAddonResult,
} from "@tides/base-library/dist/fields";
import Spinner from "@tides/base-library/dist/components/Spinner";
import { useQuery } from "@tanstack/react-query";
import VariantField from "./AddonField";
import {
  ErrorBoundary,
  FallbackProps,
  useErrorBoundary,
} from "react-error-boundary";
import { MultiAddonResult } from "@base-library/fields";

interface Props {
  product_id: string;
}

type State = {
  price: number;
  selectedOptions: IDictionary<IAddonResult>;
};

function ProductElement({ product_id }: Props) {
  const [_, setState] = useState<State>({
    price: 0,
    selectedOptions: {},
  });

  const bc = new BroadcastChannel("cart-channel");
  const { data, isLoading } = useQuery({
    queryKey: ["product", product_id],
    queryFn: ({ signal }) =>
      fetch(`/api/products/${product_id}`, { signal }).then((resp) => {
        if (!resp.ok) throw new Error("Failed to fetch product.");

        return resp.json();
      }),
    select: (current) => current as PartialProduct,
  });

  useEffect(() => {
    return () => {
      bc.close();
    };
  }, []);

  useEffect(() => {
    if (!data) return;
    for (const addon of data.addons!) {
      for (const option of addon.options) {
        if (option.default) {
          setState((old) => {
            const newState = {
              ...old,
              price: data.price + option.price,
            };

            newState.selectedOptions[addon.id] = {
              id: option.id,
              value: option.id,
              $t: "BaseAddonResult",
            } as BaseAddonResult;

            Object.values(newState.selectedOptions).forEach((result) => {
              const returned = Object.assign({ $t: result.$t }, result);

              result = returned;
            });

            const message = {
              type: "addVariant",
              data: {
                result: newState.selectedOptions[addon.id],
                price: newState.price,
                addon,
              },
            } as CartMessage;

            bc.postMessage(message);

            return newState;
          });
          break;
        }
      }
    }
  }, [data]);

  function onUpdate(
    addon: ProductAddon,
    option: AddonOption,
    sliderCount?: number
  ) {
    if (!data) return;

    setState((oldState) => {
      const newState = {
        ...oldState,
        price: data.price,
      };

      for (const selectedAddon of Object.keys(newState.selectedOptions)) {
        const foundAddon = data.addons!.find((x) => x.id === selectedAddon);

        if (!foundAddon) continue;

        if (foundAddon.id === addon.id) continue;

        const optionResult = newState.selectedOptions[selectedAddon];

        const foundOption = foundAddon.options.find(
          (x) => x.id === optionResult.id
        );

        if (!foundOption) continue;

        if (foundAddon.field_type === AddonFieldType.Slider) {
          const sliderResult = optionResult as SliderAddonResult;

          if (foundOption.reduce)
            newState.price -=
              (sliderResult.value as number) * foundOption.price;
          else
            newState.price +=
              (sliderResult.value as number) * foundOption.price;
        } else if (foundAddon.field_type === AddonFieldType.Checkbox) {
          const checkboxResult = optionResult as MultiAddonResult;

          for (const optionId of checkboxResult.value) {
            const opt = foundAddon.options.find((x) => x.id === optionId);

            if (!opt) continue;
            newState.price += opt.price;
          }
        } else {
          newState.price += foundOption.price;
        }
      }

      if (addon.field_type === AddonFieldType.Slider) {
        newState.selectedOptions[addon.id] = {
          value: sliderCount,
          id: option.id,
          $t: "SliderAddonResult",
        } as SliderAddonResult;
      } else if (addon.field_type === AddonFieldType.Checkbox) {
        const selectedOption = newState.selectedOptions[
          addon.id
        ] as MultiAddonResult;
        if (selectedOption) {
          selectedOption.value.push(option.id);

          newState.selectedOptions[addon.id] = {
            ...selectedOption,
          };
        } else {
          newState.selectedOptions[addon.id] = {
            value: [option.id],
            id: option.id,
            $t: "MultiAddonResult",
          } as MultiAddonResult;
        }
      } else {
        newState.selectedOptions[addon.id] = {
          id: option.id,
          value: option.id,
          $t: "BaseAddonResult",
        } as BaseAddonResult;
      }

      if (sliderCount) {
        if (option.reduce) newState.price -= sliderCount * option.price;
        else newState.price += sliderCount * option.price;
      }

      if (addon.field_type === AddonFieldType.Checkbox) {
        const checkbox = newState.selectedOptions[addon.id] as MultiAddonResult;
        for (const res of checkbox.value) {
          const opt = addon.options.find((x) => x.id === res);

          if (!opt) continue;

          newState.price += opt.price;
        }
      } else if (addon.field_type !== AddonFieldType.Slider) {
        newState.price += option.price;
      }

      Object.values(newState.selectedOptions).forEach((result) => {
        const returned = Object.assign({ $t: result.$t }, result);

        result = returned;
      });

      const message = {
        type: "addVariant",
        data: {
          result: newState.selectedOptions[addon.id],
          price: newState.price,
          addon,
        },
      } as CartMessage;

      bc.postMessage(message);

      return newState;
    });
  }

  useEffect(() => {
    if (isLoading) return;

    document.getElementById("product-buy-button")?.classList.toggle("hidden");
    document
      .getElementById("product-add-to-cart-button")
      ?.classList.toggle("hidden");
  }, [isLoading]);

  function onRemove(addon: ProductAddon, option: AddonOption) {
    if (!data) return;

    let result: IAddonResult;
    setState((oldState) => {
      const newState = {
        ...oldState,
      };

      if (addon.field_type === AddonFieldType.Checkbox) {
        const selectedOption = newState.selectedOptions[
          addon.id
        ] as MultiAddonResult;

        if (selectedOption.value.includes(option.id)) {
          const index = selectedOption.value.indexOf(option.id);
          const removed = selectedOption.value.splice(index, 1);

          option = addon.options.find((x) => x.id === removed[0])!;
          result = {
            ...selectedOption,
          };
          newState.selectedOptions[addon.id] = result;
        }
      } else {
        result = newState.selectedOptions[addon.id];
        delete newState.selectedOptions[addon.id];
      }

      newState.price -= option.price;

      const message = {
        type: "removeVariant",
        data: { result, price: newState.price, addon },
      } as CartMessage;

      bc.postMessage(message);

      return newState;
    });
  }

  if (isLoading) return <Spinner />;

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <section className="flex flex-col gap-4">
        {data &&
          data.addons &&
          data.addons.map((addon) => {
            if (addon.depends_on) return;

            return (
              <VariantField
                variant={addon}
                addons={data.addons!}
                key={`${addon.id}_addon`}
                onUpdate={onUpdate}
                onRemove={onRemove}
              />
            );
          })}
      </section>
    </ErrorBoundary>
  );
}

function ErrorFallback({ error }: FallbackProps) {
  const { resetBoundary } = useErrorBoundary();

  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre style={{ color: "red" }}>{error.message}</pre>
      <button onClick={resetBoundary}>Try again</button>
    </div>
  );
}

export default ProductElement;
